<template>
    <NsiForm
        :edit="true"
    />
</template>

<script>
import NsiForm from '@/components/Nsi/NsiForm'
import {mapState} from "vuex"

export default {
    name: "NsiItemEdit",
    components: {
        NsiForm
    },
    computed: {
        ...mapState({
            currentNsi: state => state.nsi.currentNsi
        }),
        breadcrumbs () {
            return [
                { title: 'Главная', link: '/' },
                { title: 'Перечень запросов на добавление НСИ', link: '/nsi-list' },
                {
                    title: `Запрос № ${this.currentNsi.number} от ${this.$options.filters.parseDate(this.currentNsi.date)}`,
                    link: `/nsi-list/${this.$route.params.id}`
                },
                { title: 'Редактирование' }
            ]
        }
    }
}
</script>
